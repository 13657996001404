import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import Toaster from '../components/commercetools-ui/toaster';
import { FrontasticProvider, LocaleStorage } from '../frontastic';
import 'tailwindcss/tailwind.css';
import '../styles/app.css';
import '../styles/components/slider.css';
import '../styles/components/default-loader.css';
import { locales } from '../frontastic/provider/locale/locales';

function FrontasticStarter({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const initialProviderValues = {
    layoutProvider: {
      isMobile: pageProps.isMobile,
    },
  };

  // Register the current locale with frontastic.
  // This must be done on initial load as network requests
  // depend on this value being correct.
  const localeConfig = locales.find((l) => l.localeCode === (router.locale || router.defaultLocale));
  LocaleStorage.locale = localeConfig.localeCode;
  // Update locale registered in frontastic when the
  // locale reported by the router changes.
  useEffect(() => {
    const localeConfig = locales.find((l) => l.localeCode === (router.locale || router.defaultLocale));
    LocaleStorage.locale = localeConfig.localeCode;
  }, [router]);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    const { locale, defaultLocale } = router;
    const lang = locales.find((l) => l.localeCode === (locale || defaultLocale)).languageCode;
    document.documentElement.lang = lang;

    const langObserver = new MutationObserver(() => {
      const { locale, defaultLocale } = router;
      const lang = locales.find((l) => l.localeCode === (locale || defaultLocale)).languageCode;

      if (document.documentElement.lang !== lang) {
        document.documentElement.lang = lang;
      }
    });
    langObserver.observe(document.documentElement, {
      attributeFilter: ['lang'],
    });

    return () => {
      langObserver.disconnect();
    };
  }, []);

  return (
    <FrontasticProvider initialOverrides={initialProviderValues}>
      <Component {...pageProps} />
      <Toaster />
    </FrontasticProvider>
  );
}

export default appWithTranslation(FrontasticStarter);
