import React, { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { locales } from './locales';

const initialState = {
  locales: locales,
  currentLocale: locales[0],
  setLocale: (value: string) => {},
};

export const LocaleContext = createContext(initialState);

const LocaleProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const [currentLocale, setCurrentLocale] = useState(locales.find((locale) => locale.localeCode === router.locale));

  const setLocale = (value: string) => {
    let localeSelected = locales.find((locale) => locale.localeCode === value);
    if (!localeSelected) {
      localeSelected = locales[0];
    }

    setCurrentLocale(localeSelected);
  };

  useEffect(() => {
    const { pathname, asPath, query } = router;
    // router.push({ pathname, query }, asPath, { locale: currentLocale.localeCode });
  }, [currentLocale]);

  const value = {
    locales,
    currentLocale,
    setLocale: setLocale,
  };

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};

export default LocaleProvider;
