import * as React from 'react';
import { SWRConfig } from 'swr';
import { fetchApiHub } from '../../lib/fetch-api-hub';
import LayoutProvider from '../layout';
import LocaleProvider from '../locale';
import SessionProvider from '../session';
import { FrontasticState, getFrontasticState } from './FrontasticState';

const initialState: FrontasticState = {
  useCart: {} as any,
  useAccount: {} as any,
  useWishlist: {} as any,
  useAdyen: {} as any,
};

interface providerProps {
  initialOverrides?: any;
}

export const FrontasticContext = React.createContext<FrontasticState>(initialState);
export const FrontasticProvider: React.FC<providerProps> = ({ initialOverrides, children }) => {
  const state: FrontasticState = getFrontasticState();
  return (
    <SWRConfig value={{ fetcher: fetchApiHub }}>
      <SessionProvider>
        <LocaleProvider>
          <LayoutProvider initialOverrides={initialOverrides}>
            <FrontasticContext.Provider value={state}>{children}</FrontasticContext.Provider>
          </LayoutProvider>
        </LocaleProvider>
      </SessionProvider>
    </SWRConfig>
  );
};

const checkContext = (context: FrontasticState) => {
  if (!context) {
    throw new Error('Expected to be wrapped in FrontasticProvider');
  }
};

export const useCart = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useCart;
};

export const useAccount = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useAccount;
};

export const useWishlist = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useWishlist;
};

export const useAdyen = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useAdyen;
};
