import { locales } from './frontastic/provider/locale/locales';

export const mapLanguage = (lang) => {
  const locale = locales.find((l) => l.localeCode === lang);

  if (!locale) {
    console.error(`Language mapper is missing language ${lang}`);
  }

  return locale?.localeBffNotation ?? locales[0].localeBffNotation;
};
