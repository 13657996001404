import { useRouter } from 'next/router';
import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { Discount } from '@Types/cart/Discount';
import { LineItem } from '@Types/cart/LineItem';
import { ProductVariantOption } from '@Types/product/ProductVariantOption';
import Session from 'react-session-api';
import useSWR, { mutate } from 'swr';
import { fetchApiHub } from 'frontastic';
import { useStorage } from '../../../helpers/hooks/useStorage';
import { actionQueue } from '../../../helpers/Queue';

export type CartDetails = {
  account?: { email: string };
  shipping?: Address;
  billing?: Address;
  custom?: { customerDomain?: string; newsletterSubscribe?: boolean; affiliateOrder?: boolean };
};

const setCartSessionItems = (cart, destroy = false) => {
  const { setItem, removeItem } = useStorage();

  if (destroy) {
    removeItem('cart_token', 'local');
    Session.clear();
  } else if (cart?.data?.accessToken && !cart?.data?.accessToken?.error) {
    setItem('cart_token', JSON.stringify(cart.data.accessToken), 'local');
  }
};

export const cartItems = () => {
  const { query } = useRouter();
  const cart = useSWR('/action/cart/getCart', fetchApiHub);
  const queryLocale = (query?.locale as string)?.replace('_', '-');

  if (cart && cart.data && cart.data.locale && queryLocale) {
    if (queryLocale !== cart.data.locale) {
      return destroyCart();
    }
  }

  setCartSessionItems(cart);

  return cart;
};

export const checkout = async (adyenData, accessToken, refreshToken) => {
  const payload = { adyenData, accessToken, refreshToken };

  return await actionQueue.add(() => {
    return fetchApiHub('/action/cart/checkout', { method: 'POST' }, payload);
  });
};

export const orderHistory = async () => {
  return await actionQueue.add(() => {
    return fetchApiHub('/action/cart/getOrders');
  });
};

export const totalOrders = async (customerEmail: string) => {
  return await actionQueue.add(() => {
    return fetchApiHub('/action/cart/getTotalOrders', { method: 'POST' }, { email: customerEmail });
  });
};

export const getProjectSettings = async () => {
  return await actionQueue.add(() => {
    return fetchApiHub('/action/project/getProjectSettings');
  });
};

export const getShippingMethods = async () => {
  return await actionQueue.add(() => {
    return fetchApiHub('/action/cart/getShippingMethods');
  });
};

export const getAvailableShippingMethods = async () => {
  return await actionQueue.add(() => {
    return fetchApiHub('/action/cart/getAvailableShippingMethods');
  });
};

export const addItem = async (variant: ProductVariantOption, quantity: number) => {
  if (quantity > variant.availableQuantity) return;

  const payload = {
    variant: {
      sku: variant.sku,
      count: quantity,
    },
  };

  const res = await actionQueue.add(() => {
    return fetchApiHub(
      '/action/cart/addToCart',
      {
        method: 'POST',
      },
      payload,
    );
  });
  mutate('/action/cart/getCart', res, { revalidate: true });

  return res;
};

export const removeItem = async (lineItemId: string) => {
  const payload = {
    lineItem: { id: lineItemId },
  };

  const res = await actionQueue.add(() => {
    return fetchApiHub(
      '/action/cart/removeLineItem',
      {
        method: 'POST',
      },
      payload,
    );
  });

  mutate('/action/cart/getCart', res, { revalidate: true });
};

export const updateItem = async (lineItem: LineItem, newQuantity: number) => {
  if (newQuantity > lineItem.variant.availableQuantity) return;

  const payload = {
    lineItem: {
      id: lineItem.lineItemId,
      count: newQuantity,
    },
  };

  const res = await actionQueue.add(() => {
    return fetchApiHub(
      '/action/cart/updateLineItem',
      {
        method: 'POST',
      },
      payload,
    );
  });

  mutate('/action/cart/getCart', res, { revalidate: true });
};

export const updateCart = async (payload: CartDetails): Promise<Cart> => {
  const res = await actionQueue.add(() => {
    return fetchApiHub(
      '/action/cart/updateCart',
      {
        headers: {
          accept: 'application/json',
        },
        credentials: 'include',
        method: 'POST',
      },
      payload,
    );
  });

  mutate('/action/cart/getCart', res, { revalidate: true });
  return res;
};

export const setShippingMethod = async (shippingMethodId: string) => {
  const payload = shippingMethodId
    ? {
        shippingMethod: {
          id: shippingMethodId,
        },
      }
    : {};

  const res = await actionQueue.add(() => {
    return fetchApiHub(
      `/action/cart/setShippingMethod?shippingMethodId=${shippingMethodId}`,
      {
        headers: {
          accept: 'application/json',
        },
        credentials: 'include',
        method: 'POST',
      },
      payload,
    );
  });

  mutate('/action/cart/getCart', res, { revalidate: true });
  return res;
};

export const redeemDiscountCode = async (code: string) => {
  const payload = {
    code: code,
  };
  const res = await fetchApiHub(
    `/action/cart/redeemDiscount`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );

  if (typeof res === 'string') {
    throw new Error();
  }
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const removeDiscountCode = async (discount: Discount) => {
  const payload = {
    discountId: discount.discountId,
  };
  const res = await fetchApiHub(
    '/action/cart/removeDiscount',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const recalculate = async (cartId: string) => {
  const payload = {
    cartId,
  };

  const recalculatedCart = await actionQueue.add(() => {
    return fetchApiHub(
      `/action/cart/recalculate`,
      {
        headers: {
          accept: 'application/json',
        },
        method: 'POST',
      },
      payload,
    );
  });

  mutate('/action/cart/getCart', recalculatedCart, { revalidate: true });

  setCartSessionItems(recalculatedCart);
  return recalculatedCart;
};

export const destroyCart = async () => {
  const payload = {};

  const cart = await actionQueue.add(() => {
    return fetchApiHub(
      `/action/cart/destroyCart`,
      {
        headers: {
          accept: 'application/json',
        },
        method: 'POST',
      },
      payload,
    );
  });

  setCartSessionItems({}, true);
  mutate('/action/cart/getCart', cart, { revalidate: true });
};

export const recreateCart = async (cartId?: string): Promise<Cart> => {
  const payload = {
    cartId,
  };

  const res = await actionQueue.add(() => {
    return fetchApiHub(
      `/action/cart/recreateCart`,
      {
        headers: {
          accept: 'application/json',
        },
        credentials: 'include',
        method: 'POST',
      },
      payload,
    );
  });

  mutate('/action/cart/getCart', res, { revalidate: true });

  setCartSessionItems(res);

  return res;
};
