import React, { useState, createContext, useEffect, useLayoutEffect } from 'react';
import {
  MIN_DESKTOP_SCREEN_SIZE,
  MIN_LAPTOP_SCREEN_SIZE,
  MIN_TABLET_SCREEN_SIZE,
  MIN_LARGE_DESKTOP_SCREEN_SIZE,
} from '../../../helpers/constants/screenSizes';

interface layoutProviderProps {
  initialOverrides?: any;
}

export const initialState = {
  isMobile: false,
  isTablet: false,
  isLaptop: false,
  isDesktop: false,
  isLargeDesktop: false,
  headerIsCollapsed: false,
  headerOffset: 0, //describes offset from bottom of header to top of page (alert banner/store locator included)
  pageWidth: 0,
  isPinkBulletin: false,
  isArticlePinkBulletin: false,
  contains: {
    alertBanner: false,
    narrowAlertBanner: false,
    bleedingBanner: false,
  },
  update: {
    setAlertBanner: (value: boolean) => {},
    setNarrowAlertBanner: (value: boolean) => {},
    setBleedingBanner: (value: boolean) => {},
    setHeaderCollapsed: (value: boolean) => {},
    calculateHeaderOffset: () => {},
    setIsPinkBulletin: (calue: boolean) => {},
    setIsArticlePinkBulletin: (calue: boolean) => {},
    setMiniCartOpenFunction: (value: boolean) => {},
  },
  miniCartOpen: false,
};

export const LayoutContext = createContext(initialState);

const HEADER_HEIGHT = 100;
const HEADER_HEIGHT_COLLAPSED = 70;

const LayoutProvider: React.FC<layoutProviderProps> = ({ initialOverrides, children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(initialOverrides?.layoutProvider?.isMobile ?? false);
  const [isLaptop, setIsLaptop] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState<boolean>(!initialOverrides?.layoutProvider?.isMobile ?? false);
  const [isLargeDesktop, setIsLargeDesktop] = useState<boolean>(false);
  const [pageWidth, setPageWidth] = useState<number>(0);
  const [headerIsCollapsed, setHeaderIsCollapsed] = useState<boolean>(false);
  const [headerOffset, setHeaderOffset] = useState<number>(0);
  const [hasAlertBanner, setHasAlertBanner] = useState<boolean>(false);
  const [hasNarrowAlertBanner, setHasNarrowAlertBanenr] = useState<boolean>(false);
  const [hasBleedingBanner, setHasBleedingBanner] = useState<boolean>(false);
  const [isPinkBulletin, setPinkBulletin] = useState<boolean>(false);
  const [isArticlePinkBulletin, setArticlePinkBulletin] = useState<boolean>(false);
  const [miniCartOpen, setMiniCartOpen] = useState<boolean>(false);

  const setIsPinkBulletin = (value: boolean) => {
    setPinkBulletin(value);
  };

  const setIsArticlePinkBulletin = (value: boolean) => {
    setArticlePinkBulletin(value);
  };

  useLayoutEffect(() => {
    const resizeHandler = () => {
      setPageWidth(window.innerWidth);
      setIsMobile(window.innerWidth < MIN_LAPTOP_SCREEN_SIZE);
      setIsTablet(MIN_TABLET_SCREEN_SIZE <= window.innerWidth && window.innerWidth < MIN_LAPTOP_SCREEN_SIZE);
      setIsLaptop(MIN_LAPTOP_SCREEN_SIZE <= window.innerWidth && window.innerWidth < MIN_DESKTOP_SCREEN_SIZE);
      setIsDesktop(window.innerWidth >= MIN_DESKTOP_SCREEN_SIZE);
      setIsLargeDesktop(window.innerWidth >= MIN_LARGE_DESKTOP_SCREEN_SIZE);
      calculateHeaderOffset();
    };

    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const calculateHeaderOffset = (headerCollapsed = false) => {
    const alertBannerHeight = document.getElementById('alert-banner')?.offsetHeight;
    // collapsed header height and mobile header height nicely match one another, so this works for both
    const headerOffset =
      (alertBannerHeight ?? 0) + (isMobile || headerCollapsed ? HEADER_HEIGHT_COLLAPSED : HEADER_HEIGHT);
    setHeaderOffset(headerOffset);
  };

  const setAlertBanner = (value: boolean) => {
    setHasAlertBanner(value);
    calculateHeaderOffset();
  };

  const setNarrowAlertBanner = (value: boolean) => {
    setHasNarrowAlertBanenr(value);
    calculateHeaderOffset();
  };

  const setBleedingBanner = (value: boolean) => {
    setHasBleedingBanner(value);
  };

  const setHeaderCollapsed = (value: boolean) => {
    setHeaderIsCollapsed(value);
    calculateHeaderOffset(value);
  };

  const setMiniCartOpenFunction = (value: boolean) => {
    setMiniCartOpen(value);
  };

  const value = {
    isMobile,
    isLaptop,
    isDesktop,
    isLargeDesktop,
    isTablet,
    headerIsCollapsed,
    headerOffset,
    pageWidth,
    isPinkBulletin,
    isArticlePinkBulletin,
    contains: {
      alertBanner: hasAlertBanner,
      narrowAlertBanner: hasNarrowAlertBanner,
      bleedingBanner: hasBleedingBanner,
    },
    update: {
      setAlertBanner,
      setNarrowAlertBanner,
      setBleedingBanner,
      setHeaderCollapsed,
      calculateHeaderOffset,
      setIsPinkBulletin,
      setIsArticlePinkBulletin,
      setMiniCartOpenFunction,
    },
    miniCartOpen: miniCartOpen,
  };

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export default LayoutProvider;
