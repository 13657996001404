import React, { createContext, useEffect, useState } from 'react';
import Session from 'react-session-api';
import { useStorage } from '../../../helpers/hooks/useStorage';

export const initialState = {
  isValid: false,
};

export const SessionContext = createContext(initialState);

const SessionProvider: React.FC = ({ children }) => {
  const [session, setSession] = useState(initialState);
  const { getItemJson } = useStorage();

  useEffect(() => {
    Session.config(true);

    const session = Session.items();

    const cartToken = getItemJson('cart_token', 'local');
    if (session && cartToken) {
      if (cartToken.expires_in * 10000 < (new Date().getTime() + 1) / 1000) {
        setSession({ ...initialState, isValid: false });
      } else {
        setSession({ ...initialState, isValid: true });
      }
    }
  }, []);

  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
