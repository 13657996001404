interface CountryRegion {
  name: string;
  abbreviation: string;
}
interface GreatBritainRegion {
  region: string;
  countyList: string[];
}

export interface CountriesLocale {
  name: string;
  abbreviation: string;
}

//Countries list for locales
export const unitedStates: CountriesLocale[] = [{ name: 'United States', abbreviation: 'US' }];
export const greatBritain: CountriesLocale[] = [
  {
    name: 'United Kingdom',
    abbreviation: 'GB',
  },
];
export const restOfTheWorldCountries: CountriesLocale[] = [
  { name: 'Australia', abbreviation: 'AU' },
  { name: 'Canada', abbreviation: 'CA' },
  { name: 'New Zealand', abbreviation: 'NZ' },
  { name: 'United Arab Emirates', abbreviation: 'AE' },
  { name: 'Singapore', abbreviation: 'SG' },
];

export const EUCountries: CountriesLocale[] = [
  { name: 'Austria', abbreviation: 'AT' },
  { name: 'Belgium', abbreviation: 'BE' },
  { name: 'Bulgaria', abbreviation: 'BG' },
  { name: 'Croatia', abbreviation: 'HR' },
  { name: 'Czech Republic', abbreviation: 'CZ' },
  { name: 'Denmark', abbreviation: 'DK' },
  { name: 'Estonia', abbreviation: 'EE' },
  { name: 'Finland', abbreviation: 'FI' },
  { name: 'France', abbreviation: 'FR' },
  { name: 'Germany', abbreviation: 'DE' },
  { name: 'Greece', abbreviation: 'GR' },
  { name: 'Hungary', abbreviation: 'HU' },
  { name: 'Ireland', abbreviation: 'IE' },
  { name: 'Italy', abbreviation: 'IT' },
  { name: 'Latvia', abbreviation: 'LV' },
  { name: 'Lithuania', abbreviation: 'LT' },
  { name: 'Luxembourg', abbreviation: 'LU' },
  { name: 'Malta', abbreviation: 'MT' },
  { name: 'Netherlands', abbreviation: 'NL' },
  { name: 'Norway', abbreviation: 'NO' },
  { name: 'Poland', abbreviation: 'PL' },
  { name: 'Portugal', abbreviation: 'PT' },
  { name: 'Republic of Cyprus', abbreviation: 'CY' },
  { name: 'Romania', abbreviation: 'RO' },
  { name: 'Slovakia', abbreviation: 'SK' },
  { name: 'Slovenia', abbreviation: 'SI' },
  { name: 'Spain', abbreviation: 'ES' },
  { name: 'Sweden', abbreviation: 'SE' },
  { name: 'Switzerland', abbreviation: 'CH' },
];

export const canadianProvinces: CountryRegion[] = [
  { name: 'Alberta', abbreviation: 'AB' },
  { name: 'British Columbia', abbreviation: 'BC' },
  { name: 'Manitoba', abbreviation: 'MB' },
  { name: 'New Brunswick', abbreviation: 'NB' },
  { name: 'Newfoundland and Labrador', abbreviation: 'NL' },
  { name: 'Northwest Territories', abbreviation: 'NT' },
  { name: 'Nova Scotia', abbreviation: 'NS' },
  { name: 'Nunavut', abbreviation: 'NU' },
  { name: 'Ontario', abbreviation: 'ON' },
  { name: 'Prince Edward Island', abbreviation: 'PE' },
  { name: 'Quebec', abbreviation: 'QC' },
  { name: 'Saskatchewan', abbreviation: 'SK' },
  { name: 'Yukon', abbreviation: 'YT' },
];

// Regions for United States
export const unitedStatesRegion: CountryRegion[] = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

// Regions for United Kingdom
export const greatBritainRegion: GreatBritainRegion[] = [
  {
    region: 'None',
    countyList: [''],
  },
  {
    region: 'England',
    countyList: [
      'Avon',
      'Bedfordshire',
      'Berkshire',
      'Buckinghamshire',
      'Cambridgeshire',
      'Cheshire',
      'Cleveland',
      'Cornwall',
      'Cumbria',
      'Derbyshire',
      'Devon',
      'Dorset',
      'Durham',
      'East Sussex',
      'Essex',
      'Gloucestershire',
      'Hampshire',
      'Herefordshire',
      'Hertfordshire',
      'Isle of Wight',
      'Kent',
      'Lancashire',
      'Leicestershire',
      'Lincolnshire',
      'London',
      'Merseyside',
      'Middlesex',
      'Norfolk',
      'Northamptonshire',
      'Northumberland',
      'North Humberside',
      'North Yorkshire',
      'Nottinghamshire',
      'Oxfordshire',
      'Rutland',
      'Shropshire',
      'Somerset',
      'South Humberside',
      'South Yorkshire',
      'Staffordshire',
      'Suffolk',
      'Surrey',
      'Tyne and Wear',
      'Warwickshire',
      'West Midlands',
      'West Sussex',
      'West Yorkshire',
      'Wiltshire',
      'Worcestershire',
    ],
  },
  {
    region: 'Wales',
    countyList: ['Clwyd', 'Dyfed', 'Gwent', 'Gwynedd', 'Mid Glamorgan', 'Powys', 'South Glamorgan', 'West Glamorgan'],
  },
  {
    region: 'Scotland',
    countyList: [
      'Aberdeenshire',
      'Angus',
      'Argyll',
      'Ayrshire',
      'Banffshire',
      'Berwickshire',
      'Bute',
      'Caithness',
      'Clackmannanshire',
      'Dumfriesshire',
      'Dunbartonshire',
      'East Lothian',
      'Fife',
      'Inverness-shire',
      'Kincardineshire',
      'Kinross-shire',
      'Kirkcudbrightshire',
      'Lanarkshire',
      'Midlothian',
      'Moray',
      'Nairnshire',
      'Orkney',
      'Peeblesshire',
      'Perthshire',
      'Renfrewshire',
      'Ross-shire',
      'Roxburghshire',
      'Selkirkshire',
      'Shetland',
      'Stirlingshire',
      'Sutherland',
      'West Lothian',
      'Wigtownshire',
    ],
  },
  {
    region: 'Northern Ireland',
    countyList: ['Antrim', 'Armagh', 'Down', 'Fermanagh', 'Londonderry', 'Tyrone'],
  },
];
