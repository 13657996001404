import { useEncryption } from './useEncryption';

type StorageType = 'session' | 'local';
type UseStorageReturnValue = {
  getItem: (key: string, type?: StorageType, shouldBeDecoded?: boolean) => string;
  getItemJson: (key: string, type?: StorageType, shouldBeDecoded?: boolean) => any;
  setItem: (key: string, value: string, type?: StorageType, shouldBeEncoded?: boolean) => boolean;
  removeItem: (key: string, type?: StorageType) => boolean;
};

export const useStorage = (): UseStorageReturnValue => {
  const { encode, decode } = useEncryption();

  const storageType = (type?: StorageType): 'localStorage' | 'sessionStorage' => `${type ?? 'session'}Storage`;

  const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')();

  const getItem = (key: string, type?: StorageType, shouldBeDecoded?: boolean): string => {
    if (isBrowser) {
      let value = window[storageType(type)][key];
      if (shouldBeDecoded) {
        value = decode(value);
      }
      return value;
    }
    return '';
  };

  const getItemJson = (key: string, type?: StorageType, shouldBeDecoded?: boolean): any => {
    const item = getItem(key, type, shouldBeDecoded);

    if (item) {
      try {
        return JSON.parse(item);
      } catch (e) {
        return false;
      }
    }

    return false;
  };

  const setItem = (key: string, value: string, type?: StorageType, shouldBeEncoded?: boolean): boolean => {
    if (isBrowser) {
      if (shouldBeEncoded) {
        value = encode(value);
      }
      window[storageType(type)].setItem(key, value);
      return true;
    }

    return false;
  };

  const removeItem = (key: string, type?: StorageType): boolean => {
    if (isBrowser) {
      window[storageType(type)].removeItem(key);
      return true;
    }

    return false;
  };

  return {
    getItem,
    getItemJson,
    setItem,
    removeItem,
  };
};
