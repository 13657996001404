import { CountryCode } from 'libphonenumber-js';
import {
  CountriesLocale,
  EUCountries,
  greatBritain,
  restOfTheWorldCountries,
  unitedStates,
} from 'helpers/constants/regions';

export interface Locale {
  localeCode: string;
  languageCode: string;
  country: string;
  countryCode: string;
  currency: string;
  currencySymbol: string;
  localeBffNotation: string;
  // used to map locale codes with the correct code, eg. UK -> GB
  pseudoCountryCode?: CountryCode;
  // used so that we know if we should display only this locale on checkout
  standAlone: boolean;
  listOfCountries: CountriesLocale[];
  public: boolean;
}

export const locales: Locale[] = [
  {
    localeCode: 'us',
    languageCode: 'en-US',
    country: 'United States',
    countryCode: 'US',
    currency: 'USD',
    currencySymbol: '$',
    localeBffNotation: 'en_US',
    standAlone: true,
    listOfCountries: unitedStates,
    public: true,
  },
  {
    localeCode: 'uk',
    languageCode: 'en-GB',
    country: 'United Kingdom',
    countryCode: 'UK',
    currency: 'GBP',
    currencySymbol: '£',
    localeBffNotation: 'en_GB',
    pseudoCountryCode: 'GB',
    standAlone: false,
    listOfCountries: greatBritain,
    public: true,
  },
  {
    localeCode: 'eu',
    languageCode: 'en-IE',
    country: 'Europe',
    countryCode: 'EU',
    currency: 'EUR',
    currencySymbol: '€',
    localeBffNotation: 'en_IE',
    pseudoCountryCode: 'IE',
    standAlone: false,
    listOfCountries: EUCountries,
    public: true,
  },
  {
    localeCode: 'intl',
    languageCode: 'en-AU',
    country: 'International',
    countryCode: 'INTL',
    currency: 'GBP',
    currencySymbol: '£',
    localeBffNotation: 'en_AU',
    pseudoCountryCode: 'AU',
    standAlone: false,
    listOfCountries: restOfTheWorldCountries,
    public: true,
  },
];
