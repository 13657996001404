type QueueItem = {
  promise: () => Promise<any>;
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
};

class Queue {
  #queue: QueueItem[] = [];
  #promisePending = false;
  #stopped = false;

  add(promise: () => Promise<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.#queue.push({
        promise,
        resolve,
        reject,
      });
      this.#handle();
    });
  }

  stop() {
    this.#stopped = true;
  }

  restart() {
    this.#stopped = false;
    this.#handle();
  }

  #handle(): void {
    if (this.#promisePending || this.#stopped) {
      return;
    }
    const item = this.#queue.shift();
    if (!item) {
      return;
    }
    try {
      this.#promisePending = true;
      item
        .promise()
        .then((value) => this.#resolve(() => item.resolve(value)))
        .catch((err) => this.#resolve(() => item.reject(err)));
    } catch (err) {
      this.#resolve(() => item.reject(err));
    }
  }

  #resolve(callback: () => void): void {
    this.#promisePending = false;
    callback();
    this.#handle();
  }
}

const actionQueue = new Queue();

export { actionQueue };
