import * as CryptoJS from 'crypto-js';

type UseEncryptionReturnValue = {
  encode: (text: String) => string;
  decode: (text: String) => string;
};

export const useEncryption = (): UseEncryptionReturnValue => {
  const encode = (text: string) => {
    if (text) {
      const encodedWord = CryptoJS.enc.Utf8.parse(text);
      const encoded = CryptoJS.enc.Base64.stringify(encodedWord);
      return encoded;
    }
    return '';
  };

  const decode = (text: string) => {
    if (text) {
      const encodedWord = CryptoJS.enc.Base64.parse(text);
      const decoded = CryptoJS.enc.Utf8.stringify(encodedWord);
      return decoded;
    }
    return '';
  };

  return {
    encode,
    decode,
  };
};
